<script setup lang="ts">
import { Button } from '#components'
import type { IManagerFieldEmits, IManagerFieldProps } from './types'
import { useFieldCache, useFieldCast } from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
})
const emit = defineEmits<IManagerFieldEmits>()

const nodeName = props.node.name
const parentValue = useVModel(props, 'parentValue', emit)
const { cast } = useFieldCast(props.node)
const initialValue = cast(
  parentValue.value[props.node.name],
  props.node.defaultValue,
)
const [value, toggle] = useToggle(initialValue)

let isUpdating = false
watch(
  () => parentValue.value[nodeName],
  (v) => {
    if (!isUpdating) {
      isUpdating = true
      value.value = cast(v)
      nextTick(() => (isUpdating = false))
    }
  },
)

watch(value, (v) => {
  if (!isUpdating) parentValue.value[nodeName] = v
})

// TODO: Validation

useFieldCache(props.node, parentValue)
</script>

<template>
  <ManagerField :node="node">
    <template #default="{ id, label }">
      <Button
        :id="id"
        :color="value === true ? 'primary' : 'secondary'"
        size="sm"
        @click="toggle()"
      >
        {{ label }}
      </Button>
    </template>
  </ManagerField>
</template>
